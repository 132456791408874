import { openSidebar } from "./sidebar";
const offerPopup = document.getElementById('offer-popup');
const closeOfferPopupBtn = document.querySelector('.popup-form__close');
const offerBtn = document.getElementById('offer-form-btn');
offerBtn.addEventListener('click', offerBtnClick);
document.addEventListener('DOMContentLoaded', function() {
    setTimeout(openOfferPopup, 10000);
})

function openOfferPopup() {
    closeOfferPopupBtn.addEventListener('click', closeOfferPopup);
    offerPopup.classList.add('fnsh-popup');
    offerPopup.style.display = 'block';
    closeOfferPopupBtn.style.opacity = "1";
}
function closeOfferPopup() {
    offerPopup.classList.remove('fnsh-popup');
    closeOfferPopupBtn.style.opacity = "0";
    offerPopup.style.display = 'none';
}
function offerBtnClick() {
    closeOfferPopup();
    openSidebar();
}