import MoveTo from "moveto";
import AOS from 'aos';
import Rellax from "rellax";
const moveTo = new MoveTo();
const triggers = document.querySelectorAll('.trigger');
triggers.forEach(trigger => moveTo.registerTrigger(trigger));

//rellax
var rellax = new Rellax('.rellax');
//smoothScroll
SmoothScroll({
  animationTime    : 800,
  stepSize         : 75,
  accelerationDelta : 30,  
  accelerationMax   : 2,   
  keyboardSupport   : true,  
  arrowScroll       : 50,
  pulseAlgorithm   : true,
  pulseScale       : 4,
  pulseNormalize   : 1,
  touchpadSupport   : true,
})
//AOS
AOS.init({
    duration: 700
  });