import Swiper from "swiper";
import { Navigation} from 'swiper/modules';

const processSwiper = new Swiper(".processSwiper", {
    modules: [Navigation],
    loop: true,
    cssMode: true,
    // navigation: {
    //   nextEl: "#process-right",
    //   prevEl: "#process-left",
    // },
    pagination: {
      el: ".swiper-pagination",
    },
    mousewheel: true,
    keyboard: true,
  });