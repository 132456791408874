import { initOfferSwiper } from "./swiper-offer";
import { initProductsSwipers } from "./swipers-projects";
import { initProjectsSwiper } from "./projects";
import { toggleMobMenu } from "./mobMenu";
import { openPointModal } from "./genplan";
import { openSidebar } from "./sidebar";
import { submitSidebarForm } from "./sidebar";
import { resetForm } from "./requestForm";
import { submitRequestForm } from "./requestForm";
import { submitSubscribeForm } from "./subscribe";
import './process.js'
import './popup-offer.js';
import './libs.js';

initOfferSwiper();
initProductsSwipers();
initProjectsSwiper();

document.querySelectorAll('.action-input span').forEach(span => {
    span.addEventListener('click', function() {
      this.previousElementSibling.focus();
    });
  });
  //projectCardsBtn
  document.addEventListener('DOMContentLoaded', function() {
    const menuControls = document.querySelectorAll('.projects__card-control');
    menuControls.forEach(function(menuControl) {
        menuControl.addEventListener('click', function() {
            this.classList.toggle('menu-open');
        });
    });
});

//form inputs
document.addEventListener('DOMContentLoaded', function() {
  function handleInputClasses(selector) {
    const inputs = document.querySelectorAll(selector + ' input');
    inputs.forEach(input => {
      input.addEventListener('input', function() {
        if (this.value !== '') {
          this.parentNode.classList.add('not-empty');
        } else {
          this.parentNode.classList.remove('not-empty');
        }
      });
      if (input.value !== '') {
        input.parentNode.classList.add('not-empty');
      }
    });
  }
  handleInputClasses('.subscribe__input');
  handleInputClasses('.request__input');
});
function techResponsiveText() {
  const techP = document.querySelectorAll('.tech__responsive-text');
  techP.forEach(p => {
    if (window.innerWidth <= 768) {
      p.innerHTML = p.innerHTML.replace(/<br>/g, '');
    } else {
      p.innerHTML = p.dataset.originalText || p.innerHTML;
    }
  });
}
document.addEventListener('DOMContentLoaded', () => {
  const techP = document.querySelectorAll('.tech__responsive-text');
  techP.forEach(p => {
    p.dataset.originalText = p.innerHTML;
  });

  techResponsiveText();
});

window.addEventListener('resize', techResponsiveText);

